import ErrorView from '../components/ErrorView'
import SEO from '../components/SEO'

export default function Custom404() {
  return(
    <>
    <SEO title="Page Not Found" path="/404"/>
    <ErrorView statusCode={404}/>
    </>
  )
}
